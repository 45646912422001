































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DepositForm extends Vue {
  @Prop() readonly banks!: Array<any>;

  public selectedBank: { bank: string; name: string; account_number: string } = {
    bank: '',
    name: '',
    // eslint-disable-next-line @typescript-eslint/camelcase
    account_number: '',
  };

  public amount: string = '';

  public slip: File|null = null;

  // public date: number = new Date().getDate();

  // public month: number = new Date().getMonth() + 1;

  // public year: number = new Date().getFullYear();

  // public hour: string = this.addZeroPrefix(new Date().getHours());

  // public minute: string = this.addZeroPrefix(new Date().getMinutes());

  // eslint-disable-next-line class-methods-use-this
  // get days(): Array<number> {
  //   return Array.from({ length: 31 }, (_, i) => i + 1);
  // }

  // eslint-disable-next-line class-methods-use-this
  // get months(): Array<{ text: string; value: number }> {
  //   return [
  //     { value: 1, text: 'มกราคม' },
  //     { value: 2, text: 'กุมภาพันธ์' },
  //     { value: 3, text: 'มีนาคม' },
  //     { value: 4, text: 'เมษายน' },
  //     { value: 5, text: 'พฤษภาคม' },
  //     { value: 6, text: 'มิถุนายน' },
  //     { value: 7, text: 'กรกฎาคม' },
  //     { value: 8, text: 'สิงหาคม' },
  //     { value: 9, text: 'กันยายน' },
  //     { value: 10, text: 'ตุลาคม' },
  //     { value: 11, text: 'พฤศจิกายน' },
  //     { value: 12, text: 'ธันวาคม' },
  //   ];
  // }

  // eslint-disable-next-line class-methods-use-this
  // get years(): Array<number> {
  //   const currentYear = new Date().getFullYear();
  //   return [
  //     currentYear, currentYear - 1,
  //   ];
  // }

  // eslint-disable-next-line class-methods-use-this
  // get hours(): Array<string> {
  //   return Array.from({ length: 24 }, (_, i) => this.addZeroPrefix(i));
  // }

  // get minutes(): Array<string> {
  //   return Array.from({ length: 60 }, (_, i) => this.addZeroPrefix(i));
  // }

  // eslint-disable-next-line class-methods-use-this
  // addZeroPrefix(value: number): string {
  //   if (value < 10) {
  //     return `0${value}`;
  //   }
  //   return String(value);
  // }

  onSelectFile(file: File|null) {
    console.log(file);
    this.slip = file;
  }

  getFormData(): FormData {
    const formData = new FormData();
    formData.append('to_bank', this.selectedBank.bank);
    formData.append('to_account_number', this.selectedBank.account_number);
    formData.append('amount', this.amount);
    if (this.slip) {
      const { slip } = this;
      formData.append('slip', slip);
    }
    // const requestTime = `${this.year}-${this.month}-${this.date} ${this.hour}:${this.minute}`;
    // console.log('requestTime', requestTime);
    // formData.append('request_time', requestTime);
    return formData;
  }

  resetFormData(): void {
    this.selectedBank = {
      bank: '',
      name: '',
      // eslint-disable-next-line @typescript-eslint/camelcase
      account_number: '',
    };
    this.amount = '';
    this.slip = null;
    this.onSelectFile(null);
    // this.date = new Date().getDate();
    // this.month = new Date().getMonth() + 1;
    // this.year = new Date().getFullYear();
    // this.hour = this.addZeroPrefix(new Date().getHours());
    // this.minute = this.addZeroPrefix(new Date().getMinutes());
  }
}
