
































































































































































































































import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import DepositForm from '@/components/DepositForm.vue';
import BaseDataTable from '@/components/BaseDataTable.vue';
import TransactionService from '@/services/TransactionService';
import BankService from '@/services/BankService';
import Auth from '@/connector/Auth.vue';

const transactionService = new TransactionService();
const bankService = new BankService();

@Component({
  components: {
    Auth,
    DepositForm,
    BaseDataTable,
  },
})
export default class DepositPage extends Vue {
  public loading: boolean = false;

  public banks: Array<any> = [];

  get depositData() {
    // eslint-disable-next-line max-len
    const data = (this.$refs.DEPOSIT_FORM as Vue & { getFormData: () => any }).getFormData();
    console.log('data', data);
    return data;
  }

  // eslint-disable-next-line class-methods-use-this
  public async requestDeposit(data: FormData) {
    try {
      this.loading = true;
      await transactionService.requestDeposit(data);
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: 'ดำเนินการเรียบร้อยแล้ว', error: false });
      (this.$refs.DEPOSIT_FORM as Vue & { resetFormData: () => void }).resetFormData();
      (this.$refs.DEPOSIT_TABLE as Vue & { refreshData: () => void }).refreshData();
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
      this.loading = false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public formatDate(time: number) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }

  // eslint-disable-next-line class-methods-use-this
  get depositListUrl() {
    return `${process.env.VUE_APP_API_ENDPOINT}/transactions/deposit`;
  }

  // eslint-disable-next-line class-methods-use-this
  public async copyAccountNumber(id: string) {
    const copyText = document.getElementById(`bank_${id}`);
    if (copyText) {
      const el = document.createElement('textarea');
      el.value = copyText.innerHTML;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$store.commit('Snackbar/showNotification', { message: `คัดลอกเลขบัญชี ${copyText.innerHTML} แล้ว`, error: false });
    }
  }

  async created() {
    const { data } = await bankService.getAllBanks();
    this.banks = [...data];
  }
}
